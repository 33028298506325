import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import L from 'leaflet';
import {useMap} from 'react-leaflet/hooks';
import {BoxSelect, FilterLine} from '.';
import {operatorService} from '../services';
import {
    availableModes,
    DEFAULT_NON_ATLAS_FILTER_VALUES,
    renderLayer,
    resetMap
} from '../utils';

export function DataLayers({filtersShow, isGeneral, onFeatureSelect, onFeaturesSelect, searchIsLoading}) {
    const map = useMap();
    const layerGroup = L.layerGroup();

    const country = useSelector(state => state.country.value.current);
    const layers = useSelector(state => true === isGeneral ? state.layers.value.general : state.layers.value.search);
    const layersSearchAdd = useSelector(state => state.layers.value.searchAdd);
    const userModes = useSelector(state => state.user.value.modes);
    const userLayers = useSelector(state => state.user.value.layers);
    const userSelection = useSelector(state => state.user.value.selection);

    if (false === isGeneral) {
        map.boxSelect.enable();
        // @TODO: understand and fix "boxselectend" firing dozens of times instead once
        map.on('boxselectend', onFeaturesSelect);
    }

    const [dataLayers, setDataLayers] = useState([]);
    const [lastHighlightedKey, setLastHighlightedKey] = useState(null);
    const [orderIndex, setOrderIndex] = useState(1); // store the highest order index of all layers

    // Search only: add a new layer to data layers to allow for adding data on top of existing layers, will be lost once search data layers are reset
    useEffect(() => {
        if (false === isGeneral && null !== layersSearchAdd) {
            const localDataLayers = dataLayers;
            const localOrderIndex = orderIndex + 1;
            localDataLayers.push({
                'layer': layersSearchAdd,
                'layerRef': renderLayer(layersSearchAdd, layerGroup, onFeatureSelect),
                'order': localOrderIndex,
            });
            setDataLayers(localDataLayers);
            setOrderIndex(localOrderIndex);

            return () => {
                setDataLayers([]);
                setOrderIndex(1);
            };
        }
    }, [layersSearchAdd]);

    useEffect(() => {
        render();

        return () => {
            resetMap(map, dataLayers, layerGroup);
            setDataLayers([]);
            setOrderIndex(1);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [layers]);

    const render = () => {
        resetMap(map, dataLayers, layerGroup);
        layerGroup.addTo(map);

        if (true === isGeneral) {
            renderGeneral();
        } else {
            renderSearch();
        }
    };

    const renderGeneral = () => {
        if (null !== layers && null !== userLayers) {
            const localDataLayers = [];
            let localOrderIndex = orderIndex;
            layers
                .filter(localLayer => true === country in userLayers && true === Object.keys(userLayers[country]).includes(localLayer._id))
                .map(localLayer => {
                    operatorService.getElements(localLayer._id)
                        .then(items => {
                            if (0 < items.length) {
                                const newLayer = {
                                    ...localLayer,
                                    'data': [],
                                    'defaults': DEFAULT_NON_ATLAS_FILTER_VALUES,
                                    'isFilterOpen': false,
                                };
                                items.forEach(item => {
                                    newLayer.data.push({
                                        '_id': item._id,
                                        'display_name': item.display_name,
                                        'geometry': item.geometry,
                                        'properties': item.metadata,
                                        'type': 'Feature',
                                    });
                                });

                                localDataLayers.push({
                                    'layer': newLayer,
                                    'layerRef': renderLayer(newLayer, layerGroup, onFeatureSelect),
                                    'order': localOrderIndex++,
                                });

                                setDataLayers([...localDataLayers]);
                                setOrderIndex(localOrderIndex);
                            }
                        });
                });
        }
    };

    const renderSearch = () => {
        const localDataLayers = [];
        if (null !== layers) {
            let localOrderIndex = orderIndex + 1;
            const availableModeLayers = availableModes?.[userModes?.[country]]?.activeLayers?.[country] || [];
            layers
                .filter(layer =>
                    true === country in userLayers && // only layers of current country
                    true === Object.keys(userLayers[country]).includes(layer._id) && // only active layers
                    (0 < layer.data.length || // only layers with data
                        (0 < layer.search_fields.length && null !== userSelection))) // except for atlas layers following a user search
                .toSorted((a, b) => { // first element will be added first and hence lowest on map
                    const indexA = availableModeLayers.indexOf(a.name);
                    const indexB = availableModeLayers.indexOf(b.name);
                    if (indexA === indexB) {
                        return 0; // doesn't matter
                    } else if (-1 === indexA) {
                        return -1; // b is listed but not a, make b first
                    } else if (-1 === indexB) {
                        return 1; // a is listed but not b, make a first
                    } else if (indexA > indexB) {
                        return -1; // b is above a (lower index), make b first
                    } else { // indexA < indexB
                        return 1; // a is above b (lower index), make a first
                    }
                })
                .forEach(layer => {
                    // add or update data
                    const layerRef = renderLayer(layer, layerGroup, onFeatureSelect);
                    localDataLayers.push({
                        'layer': layer,
                        'layerRef': layerRef,
                        'order': localOrderIndex++, // needs to be inverted since last added layer is on top
                    });
                    if (true === layer.areFeaturesHidden) {
                        layerRef.remove();
                    }
                });
            setOrderIndex(localOrderIndex);
        }

        setDataLayers(localDataLayers);
    };

    // data layer has been filtered from embedded filter line component, render result and put on top
    const update = (updatedDataLayer, bringToFront = false) => {
        updatedDataLayer.layerRef.remove(); // remove layer from map, this is for general layers
        layerGroup.addTo(map);

        setDataLayers(
            dataLayers
                // update given layer and set top order, if requested
                .map(localDataLayer => {
                    if (localDataLayer.layer._id === updatedDataLayer.layer._id) {
                        let localDataLayerOrder;
                        if (true === bringToFront) {
                            // set to highest order index
                            localDataLayerOrder = orderIndex + 1;
                            setOrderIndex(localDataLayerOrder);
                        } else {
                            // keep previous order
                            localDataLayerOrder = localDataLayer.order;
                        }

                        return {
                            'layer': updatedDataLayer.layer,
                            'layerRef': renderLayer(updatedDataLayer.layer, layerGroup, onFeatureSelect), // put layer back into layerGroup
                            'order': localDataLayerOrder,
                        };
                    }

                    // don't touch the others
                    return localDataLayer;
                })
                // have lowest order first
                .sort((a, b) => a.order < b.order ? -1 : 1)
                // bring them to top in the right order, lowest first
                .map(localDataLayer => {
                    localDataLayer.layerRef.bringToFront();
                    if (true === localDataLayer.layer.areFeaturesHidden) {
                        map.removeLayer(localDataLayer.layerRef);
                    }
                    return localDataLayer;
                })
        );
    };

    if (true === filtersShow && false === searchIsLoading && 0 < dataLayers.length) {
        return (
            <div className={`filter${true === isGeneral ? ' stripes' : ''}`}>
                <ul className="list-group list-group-flush">
                    {dataLayers
                        .sort((a, b) => a.order > b.order ? -1 : 1) // inverse since Leaflet is first added, lowest zIndex
                        .map((dataLayer, key) =>
                            <FilterLine
                                key={key}
                                dataLayer={dataLayer}
                                lastHighlightedKey={lastHighlightedKey}
                                resetDataLayer={render}
                                setDataLayers={setDataLayers}
                                setLastHighlightedKey={setLastHighlightedKey}
                                updateDataLayer={update}
                            />
                    )}
                </ul>
            </div>
        );
    }
}
