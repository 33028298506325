import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useMap} from 'react-leaflet/hooks';
import {Accordion, Button, Dropdown, DropdownButton, Form} from 'react-bootstrap';
import {
    ArrowBarUp as IconArrowBarUp,
    Eye as IconEye,
    EyeSlash as IconEyeSlash,
    Filter as IconFilter
} from 'react-bootstrap-icons';
import {FilterLineForm, FilterLineInputLock} from '.';
import {setFilters as setUserFilters} from '../redux/userSlice';
import {operatorService} from '../services';
import {
    copyDeep,
    DEFAULT_NON_ATLAS_FILTER,
    filter,
    getGeoJsonFromElements,
    isObject,
    isObjectEmpty,
    isObjectEqual,
    MODE_KEY_ACCOUNTS,
    MODE_PROSPECTION
} from '../utils';

export function FilterLine({dataLayer, lastHighlightedKey, resetDataLayer, setLastHighlightedKey, updateDataLayer}) {
    const dispatch = useDispatch();
    const map = useMap();
    const defaultFormValues = { // store default values to allow for reset
        0: dataLayer.layer.defaults,
    };

    const country = useSelector(state => state.country.value.current);
    const userFilters = useSelector(state => state.user.value.filters); // state filters, update whenever user makes a change to the filter (atlas layers) or whenever submitted (non-atlas layers)
    const userModes = useSelector(state => state.user.value.modes);

    const [filters, setFilters] = useState({}); // interface filters and their data, for atlas layers only
    const [filterKey, setFilterKey] = useState(0); // key of a list of form values
    const [formValues, setFormValues] = useState(defaultFormValues); // for non-atlas layers only
    const [isLoading, setIsLoading] = useState(false);
    const [show, setShow] = useState(true === dataLayer.layer.isFilterOpen);

    useEffect(() => {
        if (false === userFilters?.[dataLayer.layer._id]?.isAtlas) {
            setFormValues(userFilters[dataLayer.layer._id].formValues);
        }

        return () => {
            setFormValues(defaultFormValues);
        }
    }, [userFilters]);

    useEffect(() => {
        // init filters based on layer's search_fields and user filters
        if (0 < dataLayer.layer.search_fields.length) {
            // init interface filters for that layer
            const localFilters = {
                'equals': [],
                'phrase': [],
                'range': [],
            };
            // keys that require choices to be retrieved from elements
            const searchChoicesKeys = [];

            dataLayer.layer.search_fields.forEach(searchField => {
                const key = `metadata.${searchField.field}`;
                let defaultValue;
                switch (searchField.type) {
                    case 'CHOICE': // checkbox input
                        // use previously retrieved choices, if any
                        let choices = searchField.choices;
                        if ('equals' in filters) {
                            const filter = filters.equals.filter(filter => filter.field === key && 0 < filter.choices.length).pop();
                            if (undefined !== filter) {
                                choices = filter.choices;
                            }
                        }

                        // get default values from user filter, knowing that we have to aggregate several values
                        const defaultValues = [];
                        if (true === Array.isArray(userFilters[dataLayer.layer._id]?.equals)) {
                            for (const userFilterField of userFilters[dataLayer.layer._id].equals) {
                                if (key === userFilterField.key) {
                                    defaultValues.push(userFilterField.value);
                                }
                            }
                        }

                        // add search field to filter
                        localFilters.equals.push({
                            ...searchField,
                            'choices': choices,
                            'defaultValue': defaultValues,
                            'field': key,
                            'value': defaultValues,
                        });

                        // no choices provided, add key
                        if (true === Array.isArray(searchField.choices) &&
                            0 === searchField.choices.length) {
                            searchChoicesKeys.push(key);
                        }

                        break;
                    case 'FLAG': // switch (checkbox) input
                        defaultValue = getDefaultValue(key, userFilters[dataLayer.layer._id]?.equals);
                        // add search field to filter
                        localFilters.equals.push({
                            ...searchField,
                            'defaultValue': defaultValue,
                            'field': key,
                            'value': defaultValue,
                        });
                        break;
                    case 'NUM': // range (slider) input
                        defaultValue = getDefaultValue(key, userFilters[dataLayer.layer._id]?.range);
                        // add search field to filter
                        localFilters.range.push({
                            ...searchField,
                            'field': key,
                            'defaultValue': defaultValue,
                            'value': defaultValue,
                        });
                        break;
                }
            });

            // get available choices from elements
            if (0 < searchChoicesKeys.length) {
                // for all elements
                dataLayer.layer.data.forEach(element => {
                    // for all required choices, determine the element's value matching the key
                    searchChoicesKeys.forEach(searchChoicesKey => {
                        const choicesKeys = searchChoicesKey
                            .replace('metadata.', '') // remove leading metadata, which is represented by the element's properties
                            .split('.')
                        ;
                        const firstLevelKey = choicesKeys[0];
                        const secondLevelKey = choicesKeys[1];
                        let choicesValue = null;
                        let firstLevelProperties = element.properties?.[firstLevelKey];

                        // we matched, exit loop
                        if (undefined === firstLevelProperties) {
                            return;
                        }

                        // attempt matching a nested value (either in an array or an object), e.g. code from "substations.code"
                        if (true === Array.isArray(firstLevelProperties)) {
                            for (const firstLevelProperty of firstLevelProperties) {
                                if (true === secondLevelKey in firstLevelProperty) {
                                    choicesValue = firstLevelProperty[secondLevelKey];
                                    // we have a match, exit loop
                                    break;
                                }
                            }
                        } else if (undefined !== firstLevelProperties?.[secondLevelKey]) {
                            choicesValue = firstLevelProperties[secondLevelKey];
                        }

                        // skip if there's yet another level
                        if (true === Array.isArray(choicesValue) || true === isObject(choicesValue)) {
                            return;
                        }
                        // store the matched value on choices (only works for search fields of type 'equals'), if new
                        for (const [searchFieldKey, searchField] of Object.entries(localFilters.equals)) {
                            if (searchField.field === searchChoicesKey &&
                                false === searchField.choices.includes(choicesValue)) {
                                localFilters.equals[searchFieldKey].choices = [
                                    ...searchField.choices,
                                    choicesValue,
                                ];
                                // we matched, exit loop
                                break;
                            }
                        }
                    });
                });

                setFilters(localFilters);
            }
        } else {
            setShow(true === dataLayer.layer.isFilterOpen);
        }

        return () => {
            setFilters({});
        };
    }, [dataLayer.layer]);

    // disable filter button when one of the form values is invalid
    useEffect(() => {
        if (false === isObjectEqual(formValues, defaultFormValues)) {
            let isValid = false;

            // invalid if empty
            if (JSON.stringify(DEFAULT_NON_ATLAS_FILTER) !== JSON.stringify(formValues)) {
                isValid = isFormValuesValid(formValues);
            }

            setIsLoading(false === isValid);
        }

        return () => {
            setIsLoading(false);
        };
    }, [formValues]);

    const _updateUserFilter = (layerId, localFormValues) => {
        const localUserFilters = copyDeep(userFilters);
        localUserFilters[layerId].formValues = localFormValues;
        dispatch(setUserFilters(localUserFilters));
    };

    const addFieldsFromUserFilter = (localUserFilter, type, fieldsMust, fieldsShould, fieldsEmbeddedDocument) => {
        localUserFilter[type].map(filter => {
            if (null !== filter.value) {
                const keyValue = {
                    'key': filter.key,
                    'value': filter.value,
                };
                if (true === filter.embeddedDocument) {
                    if ('should' === filter.condition) {
                        fieldsEmbeddedDocument.fieldsShould[type].push(keyValue);
                    } else { // must
                        fieldsEmbeddedDocument.fieldsMust[type].push(keyValue);
                    }
                } else if ('should' === filter.condition) {
                    fieldsShould[type].push(keyValue);
                } else { // must
                    fieldsMust[type].push(keyValue);
                }
            }
        });

        return [fieldsMust, fieldsShould, fieldsEmbeddedDocument];
    };

    // persist to user filters and filter through backend
    const formSubmit = localUserFilter => {
        setIsLoading(true);

        let fieldsMust = {
            'equals': [],
            'phrase': [],
            'range': [],
        };
        let fieldsShould = copyDeep(fieldsMust); // init just like must
        let fieldsEmbeddedDocument = {
            'path': 'metadata.substations',
            'fieldsMust': copyDeep(fieldsMust), // init just like must
            'fieldsShould': copyDeep(fieldsMust), // init just like must
        };

        ['equals', 'phrase', 'range'].forEach(type => {
            [fieldsMust, fieldsShould, fieldsEmbeddedDocument] = addFieldsFromUserFilter(localUserFilter, type, fieldsMust, fieldsShould, fieldsEmbeddedDocument);
        });

        operatorService.getElements(dataLayer.layer._id, fieldsMust, fieldsShould, localUserFilter.geometry, fieldsEmbeddedDocument)
            .then(elements => {
                updateDataLayer({
                    ...dataLayer,
                    'layer': {
                        ...dataLayer.layer,
                        'data': getGeoJsonFromElements(elements),
                    },
                });
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    // filter on frontend
    const formSubmitLegacy = () => {
        setIsLoading(true);

        _updateUserFilter(dataLayer.layer._id, formValues);

        updateDataLayer({
            ...dataLayer,
            'layer': {
                ...dataLayer.layer,
                'data': filter(formValues, dataLayer.layer.data),
            },
        });

        setIsLoading(false);
    };

    // combine first and second level keys of all elements for exhaustive filtering
    const getAvailableKeys = () => {
        let availableKeys = {};
        dataLayer.layer.data.forEach(element => {
            if ('properties' in element) {
                Object.keys(element.properties).forEach(keyFirstLevel => {
                    if (false === keyFirstLevel in availableKeys) {
                        availableKeys[keyFirstLevel] = [];
                    }
                    if (null !== element.properties[keyFirstLevel] && true === isObject(element.properties[keyFirstLevel])) {
                        Object.keys(element.properties[keyFirstLevel]).forEach(keySecondLevel => {
                            if (false === availableKeys[keyFirstLevel].includes(keySecondLevel)) {
                                availableKeys[keyFirstLevel].push(keySecondLevel);
                            }
                        });
                    }
                });
            }
        });

        return availableKeys;
    };

    const getDefaultValue = (key, userFilterFields) => {
        let defaultValue = ''; // null is already used in FilterLineInputLock as third state
        if (true === Array.isArray(userFilterFields)) {
            for (const userFilterField of userFilterFields) {
                if (key === userFilterField.key) {
                    defaultValue = userFilterField.value;
                    break;
                }
            }
        }

        return defaultValue;
    };

    const isFilterValid = values => {
        return '' !== values.condition && '' !== values.key && '' !== values.value;
    };

    const isFormValuesValid = localFormValues => {
        let isValid = true;
        Object.keys(localFormValues).every(key => {
            if (false === isFilterValid(localFormValues[key])) {
                isValid = false;
                return false;
            }

            return true;
        });

        return isValid;
    };

    const renderFilters = () => {
        if (false === isObjectEmpty(filters)) {
            return (
                <Accordion defaultActiveKey={[]} flush>
                    {renderFieldsFormGroup('Flags', 'FLAG')}
                    {renderFieldsFormGroup('Nums', 'NUM')}
                    {filters.equals.filter(field => 'CHOICE' === field.type).map(fieldChoice => renderFieldsFormGroup(fieldChoice.name, 'CHOICE', fieldChoice.field/*, 'Substations' === fieldChoice.name && 'Substation' === userSelection?.type*/))}
                </Accordion>
            );
        }

        const availableKeys = getAvailableKeys();
        return (
            Object.keys(formValues).map(formValueKey =>
                <FilterLineForm
                    availableKeys={availableKeys}
                    defaults={formValues[formValueKey]}
                    handleUpdate={values => {
                        setFormValues({
                            ...formValues,
                            [formValueKey]: values,
                        });
                    }}
                    key={formValueKey}
                    submit={() => {}} // do nothing (for now)
                />
            )
        );
    }

    const renderForm = () => {
        if (false === show) {
            return;
        }

        // backend (Atlas-powered) filtering
        if (true === ['highVoltageLine', 'plot'].includes(dataLayer.layer.type)) {
            return (
                <Form className="mt-1 field-filters">
                    {renderFilters()}
                    <div className="container g-0 mt-1 text-center">
                        <div className="g-2 row">
                            <div className="col-6">
                                <div className="d-grid">
                                    <DropdownButton className="d-grid" title="More" variant="outline-secondary">
                                        {'highVoltageLine' === dataLayer.layer.type &&
                                         true === [MODE_KEY_ACCOUNTS, MODE_PROSPECTION].includes(userModes[country]) &&
                                            <Dropdown.Item className="d-grid" disabled={true === isLoading} onClick={() => {
                                                const value = MODE_KEY_ACCOUNTS === userModes[country] ? 1.3 : 2;
                                                // update + persist user filters
                                                let localUserFilters = copyDeep(userFilters);
                                                localUserFilters = updateUserFilters(localUserFilters, dataLayer.layer._id, 'must', 'range', 'metadata.capaciteInjectionDispo', value, false);
                                                localUserFilters = updateUserFilters(localUserFilters, dataLayer.layer._id, 'must', 'range', 'metadata.capaciteSoutirageDispo', value, false);
                                                dispatch(setUserFilters(localUserFilters));

                                                // update + persist filters
                                                let localFilters = copyDeep(filters);
                                                localFilters = updateFilters(localFilters, 'range', 'metadata.capaciteInjectionDispo', value);
                                                localFilters = updateFilters(localFilters, 'range', 'metadata.capaciteSoutirageDispo', value);
                                                setFilters(localFilters);

                                                formSubmit(localUserFilters[dataLayer.layer._id]);

                                                // otherwise the range filters don't update their value
                                                setShow(false);
                                            }}
                                           >Preset</Dropdown.Item>
                                        }
                                        <Dropdown.Item className="d-grid" disabled={true === isLoading} onClick={() => {
                                            // reset and persist user filters
                                            const localUserFilters = copyDeep(userFilters);
                                            localUserFilters[dataLayer.layer._id] = dataLayer.layer.defaultAtlasFilters;
                                            dispatch(setUserFilters(localUserFilters));

                                            // submit with default filters
                                            formSubmit(localUserFilters[dataLayer.layer._id]);

                                            // otherwise the range filters don't update their value
                                            setShow(false);
                                        }}>Reset</Dropdown.Item>
                                    </DropdownButton>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="d-grid">
                                    <Button disabled={true === isLoading} onClick={() => formSubmit(userFilters[dataLayer.layer._id])}>
                                        Filter
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Form>
            );
        }

        // other layers, client-side filtering
        const availableKeys = getAvailableKeys();
        return (
            <Form className="mt-3" onSubmit={event => event.preventDefault()}>
                <Form.Group>
                    {Object.keys(userFilters[dataLayer.layer._id].formValues).map(formValueKey =>
                        <FilterLineForm
                            layerName={dataLayer.layer.name}
                            availableKeys={availableKeys}
                            defaults={userFilters[dataLayer.layer._id].formValues[formValueKey]}
                            handleUpdate={values => {
                                setFormValues({
                                    ...userFilters[dataLayer.layer._id].formValues,
                                    [formValueKey]: values,
                                });
                            }}
                            key={formValueKey}
                            submit={formSubmitLegacy}
                        />
                    )}
                    <div className="container g-0 text-center">
                        <div className="g-2 row">
                            <div className="col-6">
                                <DropdownButton className="d-grid" title="More" variant="outline-secondary">
                                    <Dropdown.Item className="d-grid" onClick={() => {
                                        const lastFormValuesKey = Object.keys(formValues).map(localKey => parseInt(localKey)).pop();
                                        const localFormValues = {
                                            ...userFilters[dataLayer.layer._id].formValues,
                                            [lastFormValuesKey+1]: {},
                                        };
                                        setFormValues(localFormValues);

                                        _updateUserFilter(dataLayer.layer._id, localFormValues);
                                    }}>Add filter</Dropdown.Item>
                                    {false === isObjectEmpty(formValues) && <Dropdown.Item onClick={() => {
                                        let localFormValues = copyDeep(formValues);
                                        const lastFormValuesKey = Object.keys(formValues).map(localKey => parseInt(localKey)).pop();
                                        delete localFormValues[lastFormValuesKey];
                                        setFormValues(localFormValues);

                                        _updateUserFilter(dataLayer.layer._id, localFormValues);
                                    }}>Remove filter</Dropdown.Item>}
                                    <Dropdown.Item onClick={() => {
                                        // remove additional filters, reset first
                                        const localFormValues = {
                                            ...defaultFormValues,
                                        };
                                        setFormValues(localFormValues);
                                        resetDataLayer();

                                        _updateUserFilter(dataLayer.layer._id, localFormValues);
                                    }}>Reset data</Dropdown.Item>
                                </DropdownButton>
                            </div>
                            <div className="col-6">
                                <div className="d-grid">
                                    <Button disabled={true === isLoading} onClick={formSubmitLegacy}>
                                        Filter
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Form.Group>
            </Form>
        );
    };

    const renderFieldsFormGroup = (title, searchFieldType, fieldKey = null, isDisabled = false) => {
        const searchCondition = 'CHOICE' === searchFieldType ? 'should' : 'must';
        const searchType = ['CHOICE', 'FLAG'].includes(searchFieldType) ? 'equals' : 'range';
        const localFilters = 'CHOICE' === searchFieldType ? filters[searchType].filter(field => field.field === fieldKey) : filters[searchType].filter(field => 'CHOICE' !== field.type);

        if (0 < localFilters.length) {
            // determine the number of active fields
            let activeFilterCount;
            if ('CHOICE' === searchFieldType) { // value is an array of values, can contain ''
                activeFilterCount = true === Array.isArray(localFilters[0].value) ? localFilters[0].value.filter(value => '' !== value).length : 0;
            } else { // one value if no empty string
                activeFilterCount = localFilters.filter(filter => '' !== filter.value).length;
            }

            return (
                <Accordion.Item eventKey={title} key={title}>
                    <Accordion.Header>{title} {0 < activeFilterCount && <span className="badge ms-2 text-bg-light">{activeFilterCount}</span>}</Accordion.Header>
                    <Accordion.Body>
                        {localFilters.map((field, key) =>
                            <FilterLineInputLock
                                defaultIsLocked={'' === field.value}
                                defaultValue={userFilters[dataLayer.layer._id][searchType].filter(filter => filter.key === field.field).map(filter => filter.value).pop()}
                                field={field}
                                isDisabled={true === isDisabled}
                                key={key}
                                updateValue={newValue => {
                                    // update + persist user filters
                                    dispatch(setUserFilters(updateUserFilters(copyDeep(userFilters), dataLayer.layer._id, searchCondition, searchType, field.field, newValue, field.embeddedDocument)));
                                    // update + persist filters
                                    setFilters(updateFilters(copyDeep(filters), searchType, field.field, newValue));
                                }}
                            />
                        )}
                        {/* @TODO: this resets the user filter for the given fieldKey but doesn't unset the radio buttons */}
                        {/*{'phrase' === searchType && <a className="mt-2" onClick={() => {*/}
                        {/*    const localUserFilters = copyDeep(userFilters);*/}
                        {/*    // remove this field from user filters*/}
                        {/*    localUserFilters[layer._id][searchType] = localUserFilters[layer._id][searchType].filter(filter => filter.key !== fieldKey);*/}
                        {/*    // persist*/}
                        {/*    dispatch(setUserFilters(localUserFilters));*/}
                        {/*}} role="button">clear</a>}*/}
                    </Accordion.Body>
                </Accordion.Item>
            );
        }
    };

    const toggleDataLayer = (hide, localLastHighlightedKey) => {
        updateDataLayer({
            ...dataLayer,
            'layer': {
                ...dataLayer.layer,
                'areFeaturesHidden': hide,
            },
        });

        setFilterKey(filterKey + 1); // force render
        setLastHighlightedKey(localLastHighlightedKey);
    };

    // reflects change on active filter count
    const updateFilters = (localFilters, searchType, key, value) => {
        localFilters[searchType] = localFilters[searchType].map(filter => {
            if (filter.field === key) {
                return {
                    ...filter,
                    'value': value,
                };
            }

            return filter;
        });

        return localFilters;
    };

    const updateUserFilters = (localUserFilters, layerId, searchCondition, searchType, key, values, embeddedDocument) => {
        // remove field
        localUserFilters[layerId][searchType] = localUserFilters[layerId][searchType].filter(filter => filter.key !== key);
        // add field back
        if ('' !== values) {
            if (false === Array.isArray(values)) {
                values = [values];
            }
            values.forEach(value => localUserFilters[dataLayer.layer._id][searchType].push({
                'condition': searchCondition,
                'embeddedDocument': embeddedDocument,
                'key': key,
                'value': value,
            }));
        }

        return localUserFilters;
    };

    if (null !== dataLayer.layerRef) {
        const localLastHighlightedKey = `${dataLayer.layer._id}-${dataLayer.order}`;
        return (
            <li className={`list-group-item${localLastHighlightedKey === lastHighlightedKey ? ' highlight' : ''}`} key={`${dataLayer.layer._id}-${filterKey}`}>
                <div className="d-flex justify-content-between align-items-center">
                    <span>
                        {dataLayer.layer.name} <span className="badge bg-secondary ms-2">{dataLayer.layer.data.length}</span>
                    </span>
                    <span>
                        {true === map.hasLayer(dataLayer.layerRef) ?
                            <IconEye onClick={() => toggleDataLayer(true, localLastHighlightedKey)} role="button" size={20} title="Hide"/>
                            :
                            <IconEyeSlash onClick={() => toggleDataLayer(false, localLastHighlightedKey)} role="button" size={20} title="Show"/>
                        }
                        <IconFilter className="me-2 ms-2" onClick={() => {
                            setShow(!show);
                        }} role="button" size={20} title="Toggle filter"/>
                        <IconArrowBarUp onClick={() => {
                            setLastHighlightedKey(localLastHighlightedKey);
                            updateDataLayer(dataLayer, true);
                        }} role="button" size={20} title="Bring to front"/>
                    </span>
                </div>
                {renderForm()}
            </li>
        );
    }
}
