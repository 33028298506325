import {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {setGeneral as setLayersGeneral, setSearch as setLayersSearch} from '../redux/layersSlice';
import {setLayers as setUserLayers, setModes as setUserModes} from '../redux/userSlice';
import {Question as IconQuestion} from 'react-bootstrap-icons';
import {Button, Form} from 'react-bootstrap';
import {getAvailableModes, isObjectEmpty, setUserProfile} from '../utils';

export function Profile() {
    const dispatch = useDispatch();

    const country = useSelector(state => state.country.value.current);
    const layers = useSelector(state => state.layers.value);
    const userLayers = useSelector(state => state.user.value.layers);
    const userModes = useSelector(state => state.user.value.modes);

    const [availableLayers, setAvailableLayers] = useState([]);
    const [availableModes, setAvailableModes] = useState(getAvailableModes(country));

    const apply = () => {
        // update layers to ensure that layer changes immediately get reflected on the map
        const localLayersGeneral = availableLayers.filter(availableLayer => 'general' === availableLayer.scope);
        const localLayersSearch = availableLayers.filter(availableLayer => 'search' === availableLayer.scope);
        dispatch(setLayersGeneral(localLayersGeneral));
        dispatch(setLayersSearch(localLayersSearch));
    };

    const changeMode = event => {
        const newMode = event.target.value;
        const localUserModes = {...userModes};
        localUserModes[country] = newMode;

        dispatch(setUserModes(localUserModes));

        let localAvailableLayers = null;
        const newActiveLayers = availableModes?.[newMode]?.activeLayers?.[country];
        if (newActiveLayers !== undefined) {
            // update available layers on which the selectable list is based
            localAvailableLayers = [];
            availableLayers.forEach(availableLayer => {
                const isActive = newActiveLayers.includes(availableLayer.name);
                localAvailableLayers.push({
                    ...availableLayer,
                    'isActive': isActive,
                });
            });
            setAvailableLayers(localAvailableLayers);
        }

        apply();
        save(localAvailableLayers, localUserModes);
    };

    const changeLayer = toggledLayer => {
        const localAvailableLayers = availableLayers.map(availableLayer => {
            // toggle the value of the toggled layer
            if (toggledLayer._id === availableLayer._id) {
                return {
                    ...availableLayer,
                    'isActive': !availableLayer.isActive,
                };
            }

            // keep the others
            return availableLayer;
        });
        setAvailableLayers(localAvailableLayers);

        apply();
        save(localAvailableLayers, userModes);
    };

    const renderLayerCheckbox = layer => {
        return (
            <Form.Check
                checked={layer.isActive === true} id={layer._id} key={layer._id}
                onChange={() => changeLayer(layer)}
                label={layer.name}
                type="checkbox"
            />
        );
    }

    // persist to local storage
    const save = (localAvailableLayers, localUserModes) => {
        const localUserLayers = {...userLayers};

        if (null !== localAvailableLayers) {
            localUserLayers[country] = {};
            localAvailableLayers.filter(availableLayer => true === availableLayer.isActive).forEach(availableLayer => {
                localUserLayers[country][availableLayer._id] = availableLayer.name;
            });
            dispatch(setUserLayers(localUserLayers));
        }

        setUserProfile({
            'country': country,
            'layers': localUserLayers,
            'modes': localUserModes,
        });
    };

    // init available layers based on all layers and user's layers
    useEffect(() => {
        if (null !== layers.general && null !== layers.search && null !== userLayers) {
            const localAvailableLayers = [];
            [...layers.general, ...layers.search].forEach(localLayer => {
                localAvailableLayers.push({
                    ...localLayer,
                    'isActive': country in userLayers && Object.keys(userLayers[country]).includes(localLayer._id) === true,
                });
            });
            setAvailableLayers(localAvailableLayers.sort((a, b) => a.name > b.name ? 1 : -1));
        }

        return () => {
            setAvailableLayers([]);
        }
    }, [layers]);

    useEffect(() => {
        setAvailableModes(getAvailableModes(country));
    }, [country]);

    return (
        <>
            {false === isObjectEmpty(availableModes) && <>
                <h5>
                    Mode
                    <IconQuestion style={{'cursor': 'help'}} title={"Changing the mode will set your layer selection to the mode's default layers"}/>
                </h5>
                <Form.Select aria-label="Select your application mode" onChange={changeMode} value={userModes[country]}>
                    {Object.keys(availableModes).sort((a, b) => availableModes[a].label > availableModes[b].label ? 1 : -1).map(key => <option key={key} value={key}>{availableModes[key].label}</option>)}
                </Form.Select>
            </>}
            <h5 className="mt-2">
                Layers
                <IconQuestion style={{'cursor': 'help'}} title={"Selected layers will be searched and displayed on the map. Striped layers are general, meaning they are independent from search and always show."}/>
            </h5>
            <div className="ps-2 py-1 stripes">
                {availableLayers.filter(availableLayer => country === availableLayer.country && 'general' === availableLayer.scope).map(availableLayer => renderLayerCheckbox(availableLayer))}
            </div>
            <div className="ps-2 py-1">
                {availableLayers.filter(availableLayer => country === availableLayer.country && 'general' !== availableLayer.scope).map(availableLayer => renderLayerCheckbox(availableLayer))}
            </div>
        </>
    );
}
